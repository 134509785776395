import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import * as moment from "moment";
import { getRefererList } from "../../actions/admin";
import config from "../.././lib/config";
import { toastAlert } from "../../lib/toastAlert.js";

export default function Table() {
  const [referer, setReferer] = useState();

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    var res = await getRefererList();
    console.log(res, "res");
    var array = res.result;
    var newarray = [];
    array &&
      array.length > 0 &&
      array.map((items) => {
        newarray.push({
          user_address: items.address,
          Round: items.round,
          TokenPrice: items.tokenPrice,
          usdt_value: items.busdValue,
          angelpupp_value: items.AngelpuppValue,
          user_id: items.UserRefferalID,
          referrer_id: items.referer,
          hash: items.hash,
          date: moment(items.createdAt).format("MMMM,Do YYYY, hh:mm A"),
        });
        return newarray;
      });
    setReferer(newarray);
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 6) + "...." + addr.slice(addr.length - 3);
    } else {
      return "";
    }
  };
  async function CopyFunction(text) {
    navigator.clipboard.writeText(text);

    toastAlert("success", "Copied!");
  }

  const columns = [
    {
      name: "Sno",
      sortable: true,
      cell: (row, index) => <div data-tag="allowRowEvents">{index + 1}</div>,
    },
    {
      name: "User",
      sortable: true,
      cell: (row) => (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => CopyFunction(row.user_address)}
        >
          {halfAddrShow(row.user_address)}
        </a>
      ),
    },
    {
      name: "Round",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.Round}</div>,
    },
    {
      name: "Token Sale Price",
      width: "150px",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.TokenPrice}</div>,
    },
    {
      name: "User Referral Id",
      width: "150px",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.user_id}</div>,
    },
    {
      name: "Referrer Id",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.referrer_id}</div>,
    },
    {
      name: "USDT Token",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.usdt_value}</div>,
    },
    {
      name: "Angelpupp Token",
      width: "150px",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.angelpupp_value}</div>,
    },
    {
      name: "Hash",
      sortable: true,
      cell: (row) => (
        <a href={`${config.HashLink}${row.hash}`} target="_blank">
          {halfAddrShow(row.hash)}
        </a>
      ),
    },
    {
      name: "Date",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.date}</div>,
    },
  ];

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(referer[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    console.log(array, "gdhryh");
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "PurchaseHistory.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <div>
      <h3>Purchase History</h3>
      {referer && referer.length > 0 ? (
        <>
          <button class="btn btn-primary" onClick={() => downloadCSV(referer)}>
            Export CSV
          </button>
        </>
      ) : (
        ""
      )}

      <DataTable
        columns={columns}
        data={referer}
        noHeader
        pagination={true}
        paginationPerPage="10"
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle";
import Select from "react-select";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Web3 from "web3";
import "react-phone-input-2/lib/style.css";
import isEmpty from "../../lib/isEmpty";
import { SetClaimStatus, getClaimSetting } from "../../actions/admin";
import config from "../../lib/config.js";
import ICOABI from "../../ABI/ICOABI.json";
import { toastAlert } from "../../lib/toastAlert.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  question: "",
  answer: "",
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [activedata, setActivedata] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(false);

  const handleFormSubmit = async () => {
    const web3 = new Web3(window.ethereum);
    let accounts = await web3.eth.requestAccounts();
    let address = accounts[0];
    let chainID = await web3.eth.getChainId();

    if (address == config.adminAddr) {
      if (chainID == config.networkID) {
        setLoader(true);

        var ICOcontract = new web3.eth.Contract(
          ICOABI,
          config.singlesmartContract
        );
        console.log(autoRenewal, "autoRenewal");
        await ICOcontract.methods
          .updateCliamStatus(autoRenewal)
          .send({ from: config.adminAddr })
          .then(async (res) => {
            console.log(res, "responce=====");
            var reqData = {
              status: autoRenewal,
            };
            let { result } = await SetClaimStatus(reqData);
            console.log(result, "resuji");
            if (result.success) {
              if (autoRenewal) {
                toast.success("Enable Successfully", toasterOption);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                toast.success("Disable Successfully", toasterOption);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      } else {
        toastAlert("error", `Connect ${config.Symbol} network`);
        setLoader(false);
        return false;
      }
    } else {
      toastAlert("error", "Connect owner wallet");
      setLoader(false);
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    // let { result } = await getClaimSetting();
    // console.log(result?.Data[0]?.status, "22222");
    const web3 = new Web3(window.ethereum);
    var ICOcontract = new web3.eth.Contract(ICOABI, config.singlesmartContract);
    var claimestatus = await ICOcontract.methods.claimStatus().call({});
    console.log(claimestatus, "claimestatus");
    setAutoRenewal(claimestatus);

    // if (result?.Data[0]?.status == true) {
    //   setActivedata(false);
    // } else if (result?.Data[0]?.status == false) {
    //   setActivedata(true);
    // }
  };

  //   const toggleDisplay = (e) => {
  //     console.log(e, "eeeeee");
  //     if (e == true) {
  //       setActivedata(false);
  //     } else {
  //       setActivedata(true);
  //     }
  //   };

  const handleChange1 = () => {
    console.log(autoRenewal, "autoRenewal");
    setAutoRenewal(!autoRenewal);
  };

  return (
    // formData.map(formData => ()
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Claim Setting</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <br /> <br />
                  <BootstrapSwitchButton
                    checked={autoRenewal}
                    onstyle="success"
                    offstyle="danger"
                    onlabel="Enabled"
                    offlabel="Disabled"
                    width={120}
                    onChange={handleChange1}
                  />
                  {/* <Switch onChange={handleChange1} checked={autoRenewal} /> */}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit" onClick={handleFormSubmit}>
                Submit
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon,
} from "@material-ui/icons";

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  // {
  //   id: "security",
  //   path: "/securityType",
  //   isSideMenu: false,
  //   isEdit: false,
  //   isRestricted: 'common'
  // },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  /* Dashboard */
  // {
  //   id: "dashboard",
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Dashboard,
  //   isSideMenu: true,
  //   isEdit: false,
  //   isRestricted: "single",
  // },

  /* Dashboard */
  // {
  //   path: "#",
  //   id: "dashboard",
  //   name: "Dashboard",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Dashboard Lists",
  //       path: "/dashboard",
  //       icon: Person,
  //     },
  //   ],
  // },

  // {
  //   path: "#",
  //   id: "faq",
  //   name: "FAQ",
  //   icon: LiveHelp,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "FAQ List",
  //       path: "/faqList",
  //       icon: List,
  //     },
  //   ],
  // },

  {
    path: "#",
    id: "History",
    name: "History",
    icon: History,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Purchase History",
        path: "/RefererPage",
        icon: List,
      },
      {
        name: "Claim History",
        path: "/claimHistory",
        icon: List,
      },
    ],
  },
  {
    path: "#",
    id: "UpdateDate",
    name: "Update Dates",
    icon: Settings,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Update Stage end dates",
        path: "/updateDates",
        icon: Settings,
      },
    ],
  },

  {
    path: "#",
    id: "Settings",
    name: "Settings",
    icon: Settings,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Claim Setting",
        path: "/ClaimSetting",
        icon: Settings,
      },
    ],
  },
];

export default dashboardRoutes;

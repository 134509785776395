import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import * as moment from "moment";
import { getClaimHistory } from "../actions/admin";

export default function Table() {
  const [claimData, setClaim] = useState();

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    var res = await getClaimHistory();
    console.log(res, "res");
    setClaim(res?.result);
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const columns = [
    {
      name: "Sno",
      sortable: true,
      cell: (row, index) => <div data-tag="allowRowEvents">{index + 1}</div>,
    },
    {
      name: "Address",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{halfAddrShow(row.address)}</div>
      ),
    },
    {
      name: "Amount",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{row.amount}</div>,
    },
    {
      name: "Hash",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{halfAddrShow(row.hash)}</div>
      ),
    },
    {
      name: "Date",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {moment(row.createdAt).format("MMMM,Do YYYY, hh:mm A")}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={claimData}
        noHeader
        pagination={true}
        paginationPerPage="10"
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
      />
    </div>
  );
}

let key = {};

let Envname = "demo";

if (Envname === "demo") {
  console.log("Set demo Config===");
  const API_URL = "https://angelpupapi.wearedev.team"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x9C71F5f63a15b68a0a0817A82dba771970Cb2492", //live
    adminAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    networkID: 43113,
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
    Symbol: "AVAX",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    singlesmartContract: "0x7Ce8c1B882eD7de17696B8c253d9b6636e47c112", //demo
    networkID: 43113,
    adminAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
    HashLink: "https://testnet.snowtrace.io/tx/",
    Symbol: "AVAX",
  };
}

export default key;

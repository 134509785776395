import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from "./components/Route/ConditionRoute";

// import pages
import LoginPage from "./pages/LoginPage/Login";

import Dashboard from "./pages/Dashboard/Dashboard";

import store from "./store";

// import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import globalStyle from "./style/global.style";

// import action
import { decodeJwt } from "./actions/jsonWebToken";
import EditProfile from "./pages/AdminProfile/EditProfile";

import FaqAdd from "./pages/Faq/faqAdd";
// import FaqList from "./pages/Faq/faqList";
import FaqUpdate from "./pages/Faq/faqUpdate";
import changepassword from "./pages/ChangePassword/Changepasswpord";
import RefererPage from "./pages/Referer/Referer";
import ClaimHistory from "./pages/Claim/ClaimHistory";
import ClaimSetting from "./pages/Settings/ClaimSetting";
import table from "./pages/table";
import UpdateDates from "./pages/UpdateDates/UpdateDates";

const history = createBrowserHistory();

const App = () => {
  const { isAuth } = store.getState().currentUser;

  useEffect(() => {
    if (isAuth != true && localStorage.admin_token) {
      decodeJwt(localStorage.admin_token, store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> */}
        <Router history={history} basename={"/"}>
          <Switch>
            {/* Auth Route */}
            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />

            {/* <ConditionRoute
              path="/faqList"
              component={FaqList}
              layout={Admin}
              type={"private"}
            /> */}

            {/* Private Route */}
            <ConditionRoute
              path="/dashboard"
              component={Dashboard}
              layout={Admin}
              type={"private"}
              store={store}
            />

            {/* Product request */}

            {/* Ingredient request */}

            {/* EditProfile */}
            <ConditionRoute
              path="/editprofile"
              component={EditProfile}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/faqAdd"
              component={FaqAdd}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/faqUpdate/:faqId"
              component={FaqUpdate}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/updateDates"
              component={UpdateDates}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/changepassword"
              component={changepassword}
              layout={Admin}
              type={"public"}
            />

            <ConditionRoute
              path="/RefererPage"
              component={RefererPage}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/claimHistory"
              component={ClaimHistory}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/ClaimSetting"
              component={ClaimSetting}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/table"
              component={table}
              layout={Admin}
              type={"private"}
            />

            <Redirect from="/" to="/login" />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
